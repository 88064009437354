import React, { useState, useEffect, useMemo, useContext } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faClone } from '@fortawesome/free-solid-svg-icons';
import { Button, Badge, Tooltip } from '@mantine/core';
import LoadingWrapper from '../../../Atoms/Loading';
import { Loader } from '@mantine/core';
import { GlobalContext } from '../../../Home/GlobalContext';
import { filterWpsOptions } from '../../../../util/weldChecks';



const HegesztesDataEntryTable = ({ selectedSheet }) => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [tooltipContent, setTooltipContent] = useState('');
    const [wpsData, setWpsData] = useState([]);
    const [materialsData, setMaterialsData] = useState([]);
    const [material, setMaterial] = useState('');

    const apiUrl = process.env.REACT_APP_API_URL;
    const { projectIDGlobal } = useContext(GlobalContext);

    const fieldHeaderMap = {
        id: 'Mentett',
        sorszam: 'Sorszám',
        tipus: 'Típus',
        D: 'Külső átmérő',
        v: 'Falvastagság',
        wps_id: 'WPS',
        position: 'Pozíció',
        munkaora: 'Munkaóra',
    };
    
    const validationRules = {
        sorszam: 'number',
        tipus: 'text',
        D: 'number',
        v: 'number',
    };

    useEffect(() => {
        setLoading(true);

        fetch(`${apiUrl}/api/get_table?sheet_id=${selectedSheet.id}&table_name=hegesztes`)
            .then(response => response.json())
            .then(data => {
                const parsedData = JSON.parse(data);
                console.log('Hegesztes data:', parsedData);
                parsedData.sort((a, b) => a.sorszam - b.sorszam);
                setRowData(parsedData);
                setLoading(false);
            })
            .catch(() => setLoading(false));

        fetch(`${apiUrl}/api/update_sheet?sheet_id=${selectedSheet.id}`)
            .then(response => response.json())
            .then(data => {
                setMaterial(data.material);
            });
    }, [selectedSheet.id]);

    useEffect(() => {
        setLoading(true);
        fetch(`${apiUrl}/api/wps?project_id=${projectIDGlobal}`)
            .then(response => response.json())
            .then(data => {
                const parsedData = JSON.parse(data);
                console.log('WPS data:', parsedData);
                setWpsData(parsedData);
                setLoading(false);
            })
            .catch(() => setLoading(false));

        fetch(`${apiUrl}/api/materials`)
            .then(response => response.json())
            .then(data => setMaterialsData(data));
    }, [projectIDGlobal]);

    const validateRows = (rows) => {
        let isValid = true;
        let missingFields = [];

        console.log('in validateRows');
        console.log('rows:', rows);
        
        rows.forEach((row) => {
            Object.keys(validationRules).forEach((key) => {
                const value = row[key];
    
                if (validationRules[key] === 'text') {
                    // Check if value is a string and trim it, otherwise treat it as invalid if it's empty or undefined
                    if (typeof value !== 'string' || value.trim() === '') {
                        isValid = false;
                        missingFields.push(fieldHeaderMap[key]);
                    }
                } else if (validationRules[key] === 'number') {
                    // Check if value is a valid number
                    if (isNaN(value) || value === null) {
                        isValid = false;
                        missingFields.push(fieldHeaderMap[key]);
                    }
                }
            });
        });
    
        if (!isValid) {
            setTooltipContent(`Please fill out the required fields: ${missingFields.join(', ')}`);
        } else {
            setTooltipContent('');
        }
    
        return isValid;
    };

    useEffect(() => {
        console.log('missingFields:', tooltipContent);
    }, [tooltipContent]);

    const handleRowDataChanged = () => {
        if (gridRef.current && gridRef.current.api) {
            const gridApi = gridRef.current.api;
            const allData = [];
            gridApi.forEachNode((node) => allData.push(node.data));
            const isValid = validateRows(allData);
            setSaveDisabled(!isValid);
        }
    };
    

    const columnDefs = useMemo(() => [
        {
            headerName: 'Actions',
            maxWidth: 150,
            editable: false,
            cellRenderer: params => (
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <button onClick={() => cloneRow(params)} style={{ border: 'none', background: 'none' }}>
                        <FontAwesomeIcon icon={faClone} style={{ fontSize: '1rem' }} />
                    </button>
                    <button onClick={() => deleteRow(params)} style={{ border: 'none', background: 'none' }}>
                        <FontAwesomeIcon icon={faTrash} style={{ fontSize: '1rem' }} />
                    </button>
                </div>
            )
        },
        { field: 'id', headerName: fieldHeaderMap['id'], cellRenderer: (params) => !params.value ? <Badge color='red'>Nem</Badge> : <Badge color='green'>Igen</Badge> },
        { field: 'sorszam', headerName: fieldHeaderMap['sorszam'], editable: true, cellClassRules: { 'highlight-cell': params => !params.value } },
        { field: 'tipus', headerName: fieldHeaderMap['tipus'], editable: true, cellClassRules: { 'highlight-cell': params => !params.value }, cellEditor: 'agSelectCellEditor', cellEditorParams: { values: ['Körvarrat', 'Sarokvarrat', 'Szegmens', 'Csőbeültetés', 'Weldolet', 'Köszörülés', 'Vágás'] }, onCellValueChanged: (params) => setMunkaoraToNull(params) },
        { field: 'D', headerName: fieldHeaderMap['D'], editable: true, cellClassRules: { 'highlight-cell': params => isNaN(params.value) || params.value === null }, onCellValueChanged: (params) => setMunkaoraToNull(params) },
        { field: 'v', headerName: fieldHeaderMap['v'], editable: true, cellClassRules: { 'highlight-cell': params => isNaN(params.value) || params.value === null }, onCellValueChanged: (params) => setMunkaoraToNull(params) },
        {
            field: 'wps_id',
            headerName: fieldHeaderMap['wps_id'],
            editable: true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (params) => {
                const filteredOptions = filterWpsOptions(params.data, material, wpsData, materialsData);
                return {
                    values: ['', ...filteredOptions.map(wps => wps.id)]
                };
            },
            valueFormatter: params => {
                const wps = wpsData.find(wps => wps.id === params.value);
                return wps ? wps.name : params.value;
            }
        },
        {
            field: 'position',
            headerName: fieldHeaderMap['position'],
            editable: true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['', 'PA', 'PB', 'PD', 'PH']
            }
        },
        { field: 'munkaora', headerName: fieldHeaderMap['munkaora'], editable: true },
    ], [wpsData, materialsData, material]);

    const setMunkaoraToNull = (params) => {
        const rowNode = params.api.getRowNode(params.node.id);
        rowNode.setDataValue('munkaora', null);
        rowNode.setDataValue('id', null);
        handleRowDataChanged();
    };

    const findLowestAvailableSorszam = (api) => {
        let sorszam = 1;
        api.forEachNode((node) => {
            if (node.data.sorszam === sorszam) {
                sorszam++;
            }
        });
        return sorszam;
    };

    const cloneRow = (params) => {
        params.api.applyTransaction({ add: [{ ...params.data, sorszam: findLowestAvailableSorszam(params.api), id: null }] });
        handleRowDataChanged();
    };

    const deleteRow = (params) => {
        params.api.applyTransaction({ remove: [params.data] });
        handleRowDataChanged();
    };

    const gridRef = React.createRef();

    const saveData = () => {
        const gridApi = gridRef.current.api;
        const rowData = [];
        gridApi.forEachNode(node => {
            const row = {
                ...node.data,
                position: node.data.position !== undefined ? node.data.position : null,
            };
            //replace empty strings with null
            Object.keys(row).forEach(key => {
                if (row[key] === '') {
                    row[key] = null;
                }
            });
            rowData.push(row);
        });
    
        console.log('rowData:', rowData);
        setLoading(true);
    
        let requestBody = {
            data: rowData,
            sheet_id: parseInt(selectedSheet.id)
        };
    
        fetch(`${apiUrl}/api/calculate_hegesztes_normhours`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setRowData(data);
                setLoading(false);
            })
            .catch(error => {
                alert('Nem sikerült a munkaóra számolás és mentés. Ellenőrizze a beírt adatokat és próbálja újra.');
                setLoading(false);
            });
    };
    

    const newRow = () => {
        const gridApi = gridRef.current.api;
        gridApi.applyTransaction({ add: [{ sorszam: findLowestAvailableSorszam(gridApi) }] });
        handleRowDataChanged();
    };

    return (
        <>
            <style>
                {`
                .ag-header-cell-label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .ag-cell-edit-wrapper {
                    display: flex !important;
                    align-items: center !important;
                    justify-content: center !important;
                }
                .highlight-cell {
                    background-color: #ffcccc;  // Highlight color for invalid cells
                }
                `}
            </style>
            <div style={{ display: 'flex', marginBottom: '5px' }}>
                <Tooltip label={tooltipContent} disabled={!saveDisabled}>
                    <div>
                        <Button
                            onClick={saveData}
                            style={{ padding: '10px', marginTop: '10px', marginBottom: '10px', marginRight: '5px' }}
                            disabled={saveDisabled}  // Disable save button if validation fails
                        >
                            Munkaóra számolása és mentés
                        </Button>
                    </div>
                </Tooltip>

                <Button
                    onClick={newRow}
                    style={{ padding: '10px', marginTop: '10px', marginBottom: '5px' }}
                >
                    Új sor hozzáadása
                </Button>
            </div>
            <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
                <LoadingWrapper isLoading={loading} loadingComponent={<Loader />}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        domLayout='autoHeight'
                        animateRows={true}
                        ref={gridRef}
                        onCellValueChanged={handleRowDataChanged}
                        stopEditingWhenCellsLoseFocus={true}
                        gridOptions={{
                            singleClickEdit: true
                        }}
                        defaultColDef={{
                            flex: 1,
                            cellStyle: () => ({
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }),
                            onCellValueChanged: (params) => {
                                const rowNode = params.api.getRowNode(params.node.id);
                                rowNode.setDataValue('id', null);
                                handleRowDataChanged();
                            },
                            minWidth: 100,
                        }}
                    />
                </LoadingWrapper>
            </div>
        </>
    );
};

export default HegesztesDataEntryTable;
